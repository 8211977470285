import React from 'react';
import logo from './logo.svg';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { BrowserRouter as Router, Link, NavLink, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Transaction from './components/Transaction';

class App extends React.Component {
  componentDidMount() {
  }

  render() {
    return (
      <Router>
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container>
            <Navbar.Brand>
              <Link className="link-dark link-underline link-underline-opacity-0" to="/">Panda Express League: Trades</Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Navbar.Text>
                  <Link className="link-dark link-underline link-underline-opacity-0" to="/">Home</Link>
                </Navbar.Text>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
  
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/trade/:id" Component={Transaction} />
          <Route path="/trades" element={<Home />} />
          <Route path="/trades/trade/:id" Component={Transaction} />
        </Routes>
      </Router>
    );
  }
}

export default App;
